import React from 'react';
import { Link } from "react-router-dom";
import Contacto from './contacto';


export default class Home extends React.Component {

    
    constructor(props){
        super(props);

        this.state = {
            slide : 'one',
            count : 0,
            contacto: false
        }  
    }

    changeSlide() {
        let numbers = ['cero','one','two','three','four'];
        this.setState({count : this.state.count +1});
        this.state.count === 4 ? this.setState({ count: 0 }) : this.setState({ slide: numbers[this.state.count] })
    }

    checkLocatation(location) {
        switch (location) {
            case '#contacto':
                document.getElementById('contacto').scrollIntoView()
                break;
            case '#quienes':
                window.scrollTo(0, 200);
                break;
            case '':
                default: 
                window.scrollTo(0, 0);
                break;


        }
    }

    
    componentDidMount(){        
        this.checkLocatation(this.props.location.hash);

        let countSlide = setInterval(() => {
            this.changeSlide();
        }, 5000)
        this.setState(
            { countSlide: countSlide }
        )
    }

    componentWillUnmount() {
        clearInterval(this.state.countSlide);
    }

    componentWillReceiveProps(nextProps){

        this.checkLocatation(nextProps.location.hash);
    }
    
    
    render(){ 
        

        return (
            <section className="home">
                <article className="slider">
                    <div className={'slider-wrapper ' + this.state.slide} >
                        <div className="slide">
                            <img src={require('./img/slide.jpg')} alt="slide"/>
                        </div>

                        <div className="slide">
                            <img src={require('./img/slide2.jpg')} alt="slide" />
                        </div>

                        <div className="slide">
                            <img src={require('./img/slide3.jpg')} alt="slide" />
                        </div>
                    </div>
                </article>
    
                <article className="nosotros" id="nosotros">
                    <div className="two-wrap">
                        <div className="info">
                            <h2>¿Quiénes Somos?</h2>
                            <p>GRPLASTIC es una empresa 100% mexicana, líder en fabricación y distribución de ganchos e insumos para la industria del vestido.</p> 
                            <p>Con más de 20 años de experiencia en el mercado, atendemos a nuestros clientes con los más altos estándares de calidad y experiencia.</p> 
                            <p>Ofrecemos productos incluyendo una gran variedad de modelos de ganchos para ropa de diferentes tipos de plástico, así como los fabricados en alambre; ademas de contar con talleros, cubre-polvo,  tallas e insumos relacionados para los fabricantes de ropa</p>
                        </div>
                            <img src={require('./img/camisa.png')} alt="camisas"/>
                    </div>
                </article>
    
                <article className="productos">
                    <div className="two-wrap rev">
                        <img src={require('./img/prod.png')} alt="ganchos"/>
                        <div className="info">
                            <h2>NUESTROS <br/> PRODUCTOS</h2>
                            <p>Tomando como base la fabricación de gancho para ropa, la experiencia nos ha ayudado a entender las necesidades de los fabricantes de ropa, por lo que atendemos de manera puntual sus requerimientos. No obstante, ademas de contar con múltiples modelos de gancho, tambien contamos con diferentes productos que permiten complementar nuestra oferta hacia la industria textil.</p>
                            <p>Por otro lado, al contar con la infraestructura necesaria para la inyección, ponemos a la disposición de nuestros clientes la maquila de articulos de plástico para poder fabricarlos, de acuerdo a cualquier especificación.</p>
                        </div>
                    </div>
                </article>
    
                <article className="sections">
                    <div className="three-wrap">
                        <div className="sect">
                            <img src={require('./img/ropa.jpg')} alt="ropa"/>
                            <div className="info">
                                <h3>Ganchos para Ropa</h3>
                                <Link to="/productos">Ver Productos</Link>
                            </div>
                        </div>
                        <div className="sect">
                            <img src={require('./img/otros.jpg')} alt="otros"/>
                            <div className="info">
                                <h3>Otros Productos</h3>
                                <Link to="/otros-productos">Ver Productos</Link>
                            </div>
                        </div>
                        <div className="sect">
                            <img src={require('./img/maquila.jpg')} alt="maquila"/>
                            <div className="info">
                                <h3>Servicios de maquila</h3>
                                <Link to="/maquila">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                </article>
    
                <Contacto />
    
                <footer></footer>
            </section>
        )
    }


}

