import React  from 'react';
import { Link } from "react-router-dom";
import productos from './productos.js';
import {GlassMagnifier} from "react-image-magnifiers";

export default class Producto extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            productoId : props.match.params.id,
            modal: false
        }
    }

    componentWillMount() {
        let produto = productos.find( producto => {
            return producto.id === this.state.productoId
        })

        this.setState(
            {prod : produto, image: produto.images[0]},
        )


        console.log(produto)
    }

    componentDidMount(){
        window.scrollTo(0,0);
    }

    setImage = (elem) => {
       this.setState(
           {image : elem}
       )
    }

    modal = () => {
        this.state.modal === true ? this.setState({ modal: false }) : this.setState({ modal: true }) ;
    }

    render() {
        return(
            <section className="product-section">
                <div className={this.state.modal === true ? 'modal-wrapper active' : 'modal-wrapper'}>
                    <div className="modal">
                        <button onClick={this.modal}>x</button>
                        <img src={require("../img/productos/" + this.state.image)} alt=""/>
                    </div>
                </div>
                <article>
                    <div className="images">
                        <button onClick={this.modal}>
                            <img src={require('../img/full-size.svg')} alt="full"/>
                        </button>
                        <GlassMagnifier className="image" 
                            imageSrc={require("../img/productos/" + this.state.image)}
                            largeImageSrc={require("../img/productos/" + this.state.image)}
                            imageAlt="Example"
                            magnifierSize="30%"
                            magnifierBorderSize={5}
                            magnifierBorderColor="rgba(255, 255, 255, .5)"
                        />
                        <div className="min">
                            {this.state.prod.images.map(
                                images => {
                                    return <img className={images === this.state.image ? 'none' : ''} key={images} onClick={this.setImage.bind(this, images)} src={require("../img/productos/" + images)} alt=""/>
                                }
                            )}
                        </div>
                        <img className="logo" src={require('../img/logo-prod.svg')} alt=""/>
                    </div>
                    <div className="info">
                        {this.state.prod.categoria === 'ganchos' && <Link to="/productos">Regresar</Link>}
                        {this.state.prod.categoria === 'otros' && <Link to="/otros-productos">Regresar</Link>}
                        <h1>{this.state.prod.name}</h1>
                        
                        <h2>Descripción General</h2>
                        <p>{this.state.prod.descripcion}</p>
                            
                        {(this.state.prod.colores || this.state.prod.material || this.state.prod.medidas) && <h2>Disponibilidad de Material</h2>}
                        {this.state.prod.colores && <div className="prop">
                            <h3>Colores</h3>
                            <div className="colores">
                                { this.state.prod.colores.map(
                                    colores => {
                                        return <div className={`color ${colores}`} key={colores}>
                                            <span></span>
                                            <p>{colores}</p>
                                        </div>
                                    }
                                ) }
                            </div>                        
                        </div>}

                        {this.state.prod.material && <div className="prop">
                            <h3>Material</h3>
                            <p>{this.state.prod.material}</p>
                        </div>}

                        {this.state.prod.medidas && <div className="prop">
                            <h3>Medidas</h3>
                            <ul className="medidas">
                                {this.state.prod.medidas.map(
                                    medidas => {
                                        return <li key={medidas}>
                                            {medidas}
                                        </li>
                                    }
                                )}
                            </ul>
                        </div>}
                        <div className="flex">
                            <div className="extra">
                                <img src={require('../img/entrega.svg')} alt="icono"/>
                                <p>Entregamos a toda la república</p>
                            </div>
                            <div className="extra">
                                <img src={require('../img/precio.svg')} alt="icono" />
                                <p>Precios especiales a distribuidores y mayoristas</p>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
        )
    }
}