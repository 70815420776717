


export const productos = [
    {
        id: '1',
        name: 'Gancho Estándar',
        images: ['g01.png','g_01.png'],
        descripcion: 'Utilizado de manera general para todo tipo de prendas, por su barra inferior y sus ranuras en la parte superior permite la sujeción de blusas, pantalones e incluso vestidos',
        colores: ['blanco','negro','natural'],
        material: 'Polipropileno',
        medidas: ['Acho total de 15 Pulgadas.','Al Interior de la barra para colgar pantalón mide 12 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '2',
        name: 'Gancho de Impacto',
        images: ['g02.png','g_02.png'],
        descripcion: 'Principalmente se utiliza para colgar blusas y camisas, es un gancho delgado que permite colocar varias prendas en un rack.',
        colores: ['natural'],
        material: 'Poli estireno',
        medidas: ['Disponible en 10, 12, 14, 16 y 18 Pulgadas de ancho'],
        categoria: 'ganchos'
    },
    {
        id: '3',
        name: 'Gancho para Bebe',
        images: ['g03.png', 'g_03.png'],
        descripcion: 'Diseñado para prendas de bebe. Cuenta con barra inferior que permite colgar incluso un conjunto.',
        colores: ['blanco','negro','natural'],
        material: 'Polipropileno',
        medidas: ['Ancho Total de 10 Pulgadas.','Dimensión en la barra interior para colgar pantalón de 9.5 pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '4',
        name: 'Gancho Infantil "Avión"',
        images: ['g04.png', 'g_04.png'],
        descripcion: 'Nombrado así por su peculiar forma, está diseñado para poder colgar una prendar en la parte superior y en la inferior por sus pinzas plásticas algún pantalón o short infantil',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['12 Pulgas de ancho'],
        categoria: 'ganchos'
    },
    {
        id: '5',
        name: 'Gancho para Camisa',
        images: ['g05.png', 'g_05.png'],
        descripcion: 'Diseñado para colgar blusas o camisas, permitiéndole dar forma y estilizarla..',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['17 Pulgadas de ancho'],
        categoria: 'ganchos'
    },
    {
        id: '6',
        name: 'Gancho Triangular',
        images: ['g06.png', 'g_06.png'],
        descripcion: 'Por su tamaño y forma es utilizado para colgar ropa infantil, cuenta con una barra inferior que permite colgar pantalones.',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['Ancho total de 13 Pulgadas.','Interior de la barra para colgar pantalón 11 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '7',
        name: 'Gancho de Cuerpo',
        images: ['g07.png', 'g_07.png'],
        descripcion: 'Utilizado para colgar conjuntos infantiles, cuenta con diferentes dimensiones y formas que permiten colgar conjuntos',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['9.5* 13 pulgadas', '9.5 * 11 pulgadas','11*15 pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '8',
        name: 'Gancho corsetero',
        images: ['g08.png', 'g_08.png'],
        descripcion: 'Diseñado para colgar prendas interiores de mujer, por su tamaño y estilo.',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Resina y polipropileno',
        medidas: ['10 Pulgadas de ancho'],
        categoria: 'ganchos'
    },
    {
        id: '9',
        name: 'Gancho de Chamarra o Sweater con Garfio fijo',
        images: ['g09.png', 'g_09.png'],
        descripcion: 'Gancho para colgar prendas pesadas, cuenta con ranuras en la parte superior que evitan que la prenda se deslice y pierda forma al colgar.',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['16 Pulgadas de Ancho'],
        categoria: 'ganchos'
    },
    {
        id: '10',
        name: 'Gancho de Chamarra con Garfio Movible',
        images: ['g10.png', 'g_10.png'],
        descripcion: 'Gancho para colgar prendas pesadas, cuenta con ranuras en la parte superior que evitan que la prenda se deslice y pierda forma al colgar. El garfio es metálico y cromado, permitiendo dar más vista y movimiento al gancho',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Resina y polipropileno',
        medidas: ['15 y 17 Pulgadas de Ancho'],
        categoria: 'ganchos'
    },
    {
        id: '11',
        name: 'Gancho de Alambre para Falda',
        images: ['g11.png', 'g_11.png'],
        descripcion: 'Gancho que se utiliza por sus ranuras en la parte superior para colgar faldas o vestidos',
        colores: ['negro'],
        material: 'Alambre',
        medidas: ['16 Pulgadas de Ancho'],
        categoria: 'ganchos'
    },
    {
        id: '12',
        name: 'Gancho de Alambre para tintorería',
        images: ['g12.png', 'g_12.png'],
        descripcion: 'Gancho utilizado por las tintorerías, debido al espacio que ocupan, disponible en 2 tamaños de acuerdo al tipo de prenda que se desea colgar',
        colores: ['negro'],
        material: 'Alambre',
        medidas: ['Infantil: 14 Pulgadas de ancho','Adulto: 16 Pulgadas de Ancho'],
        categoria: 'ganchos'
    },
    {
        id: '13',
        name: 'Gancho de Alambre para Pantalón',
        images: ['g13.png', 'g_13.png'],
        descripcion: 'Gancho con espacio para colocación del pantalón, por sus características ideal para uso en tintorerías para este tipo de prendas',
        colores: ['negro'],
        material: 'Alambre',
        medidas: ['12 Pulgadas de Ancho'],
        categoria: 'ganchos'
    },
    {
        id: '14',
        name: 'Gancho para Pantalón con Pinza y Garfio Movible',
        images: ['g14.png', 'g_14.png'],
        descripcion: 'Gancho utilizado para colgar pantalón, tiene pinzas que permiten la sujeción de manera horizontal',
        colores: ['natural'],
        material: 'Resina',
        medidas: ['8, 10 y 12 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '15',
        name: 'Gancho para Pantalón con Pinzas y Garfio Fijo',
        images: ['g15.png', 'g_15.png'],
        descripcion: 'Gancho utilizado para colgar pantalón, tiene pinzas que permiten la sujeción de manera horizontal. La diferencia con el modelo G14 es el que el garfio es fijo.',
        colores: ['negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['8, 10 y 12 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '16',
        name: 'Gancho Conector',
        images: ['g16.png', 'g_16.png'],
        descripcion: 'Con apertura superior que permite su colocación en otro gancho que por lo particular es el de camisa',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['10 y 12 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '17',
        name: 'Gancho para Traje',
        images: ['g17.png', 'g_17.png'],
        descripcion: 'Utilizado para soportar prendas pesadas debido al grosor y material del que está hecho. Este modelo tiene una barra inferior que da oportunidad de colgar pantalones.',
        colores: ['negro'],
        material: 'Polipropileno',
        medidas: ['Caballero: 16.5 Pulgadas para colgar en la parte superior y en la inferior 12 Pulgadas', 'Juvenil:14 Pulgadas para colgar en la parte superior y','Infantil: 11.5 Pulgadas para colgar en la parte superior y en la inferior 9 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '18',
        name: 'Gancho para Saco (sin barra inferior)',
        images: ['g18.png', 'g_18.png'],
        descripcion: 'Diseñado para soportar sacos o chamarras, este modelo permite dar forma y soporte a este tipo de prendas sin la incorporación de prendas debajo',
        colores: ['negro'],
        material: 'Polipropileno',
        medidas: ['16.5 Pulgadas de ancho'],
        categoria: 'ganchos'
    },
    {
        id: '20',
        name: 'Gancho para Short',
        images: ['g20.png', 'g_20.png'],
        descripcion: 'Utilizado para ropa de conjunto, principalmente por sus pinzas de plástico para colocar short o pantalones ligeros de manera horizontal.',
        colores: ['blanco', 'negro', 'natural'],
        material: 'Polipropileno',
        medidas: ['10 y 12 Pulgadas'],
        categoria: 'ganchos'
    },
    {
        id: '21',
        name: 'Bolsas de Plástico',
        images: ['bolsas.png'],
        descripcion: 'Bolsa de empaque, la tenemos en diferentes medidas y calibres',
        colores: ['negro', 'natural'],
        categoria: 'otros'
    },
    {
        id: '22',
        name: 'Caballete',
        images: ['caballete.png'],
        descripcion: 'Base de cartón, utilizada sobre el gancho de alambre para evitar marcar la prenda durante el colgado',
        categoria: 'otros'
    },
    {
        id: '23',
        name: 'Cubrepolvo',
        images: ['cubrepolvo.png'],
        descripcion: 'Cubierta para mantener las prendas protegidas del polvo. Se tiene en diversos calibres y tamaños',
        medidas: ['40x60','50x70','50x80','50x90','50x120','60x90','60x120'],
        categoria: 'otros'
    },
    {
        id: '24',
        name: 'Instrucciones de lavado',
        images: ['etiquetas.png'],
        descripcion: 'En material pellon y estampadas o bordadas en tela',
        categoria: 'otros'
    },
    {
        id: '25',
        name: 'Pistola para Plastiflechas',
        images: ['pistolaflechas.png'],
        descripcion: 'Utilizadas para colocar etiquetas de cárton a las prendas, o adicionar precio a las mismas',
        categoria: 'otros'
    },
    {
        id: '26',
        name: 'Plastiflechas',
        images: ['plastiflechas.png'],
        descripcion: 'Plástico utilizado para colgar etiquetas a las prendas.(Utilizadas en conjunto con las Pistolas de Plastiflechas)',
        categoria: 'otros'
    },
    {
        id: '27',
        name: 'Tallas de Tela y Bordadas',
        images: ['tallerosbodrados.png'],
        descripcion: 'Tallas de cualquier número o letra, en material pellón, estampadas o bordadas en tela.',
        categoria: 'otros'
    },
    {
        id: '28',
        name: 'Talleros para Gancho',
        images: ['tallerosparagancho.png'],
        descripcion: 'Indicadores para los ganchos que permiten distinguir y ordenar por color y numero a las prendas, cuando esta colgadas',
        categoria: 'otros'
    },

]

export default productos;

