import React, {useState} from 'react';
import logo from './img/logo.png'
import { Link } from "react-router-dom";

function Header() {

    const [menu, setMenu] = useState(false);
    
    let openMenu = () => {
        menu === false ? setMenu(true) : setMenu(false);
    }
    
    return(
        <header>
            <div className="wrap">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="logo"/>
                    </Link>
                </div>
                <button className="ham" onClick={openMenu}>
                    <img src={require('./img/ham.svg')} alt="ham"/>
                </button>
                <div className={menu ? 'info active' : 'info'}>
                    <button className="close" onClick={openMenu}>
                        <img src={require('./img/close.svg')} alt="close"/>
                    </button>
                    <div className="social">
                        <div className="circle home">
                            <Link to="/">
                                <img src={require('./img/home.svg')} alt="home"/>
                            </Link>
                        </div>
                        <div className="circle whats">
                            <a href="https://wa.me/525562295063">
                                <img src={require('./img/whats.svg')} alt="whats"/>
                            </a>
                        </div>
                        <div className="circle mail">
                            <Link onClick={openMenu} to={{ pathname: "/", hash: "#contacto", state: { contacto: true } }}>
                                <img src={require('./img/mail.svg')} alt="mail"/>
                            </Link>
                        </div>
                        <div className="circle face">
                            <img src={require('./img/face.svg')} alt="face"/>
                        </div>
                    </div>
                    <ul>
                        <li>
                            <Link onClick={openMenu} to="/">Inicio</Link>
                        </li>
                        <li><Link onClick={openMenu} to={{ pathname: "/", hash: "#quienes", state: { quienes: true } }}>Quiénes somos</Link></li>
                        <li>
                            <Link onClick={openMenu} to={{ pathname: "/productos", hash: "productos", state: { productos: true } }}>Productos</Link>
                        </li>
                        <li><Link onClick={openMenu} to={{ pathname: "/", hash: "#contacto", state: { contacto: true } }}>Contacto</Link></li>
                    </ul>
                </div>
            </div>
        </header>
    )
    
}

export default Header;