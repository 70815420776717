import React from 'react';
import { Link } from "react-router-dom";
import Contacto from './contacto';

export default class Maquila extends React.Component {


    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return <section className="maquila productos-section" >
            <article className="slider">
                <div className="slider-wrapper">
                    <div className="slide">
                        <img src={require('./img/slide-maquila.png')} alt="slide" />
                        <h2>Servicios <br /> de maquila</h2>
                    </div>
                </div>
            </article>

            <article className="inyeccion">
                <div className="two-wrap">
                    <div className="info">
                        <h2>Inyección de plástico</h2>
                        <p>Bajo el contexto de que contamos con la infraestructura necesaria para la inyección del plástico, ofrecemos la fabricación de cualquier articulo al mayoreo que requieran nuestros clientes.</p>
                        <p>Nos apegamos a los estándares y especificaciones de acuerdo a los requisitos que nuestros clientes determinen y a los Niveles de Servicios que se comprometan.</p>
                        <p>El servicio puede darse con el molde que nuestros clientes tengan o en su caso apoyamos en la realización del mismo</p>
                    </div>
                    <img src={require('./img/maquila-1.png')} alt="maquila" />
                </div>
            </article>

            <article className="sections">
                <h3>TAMBIÉN TE RECOMENDAMOS</h3>
                <div className="three-wrap">
                    <div className="sect">
                        <img src={require('./img/ropa.jpg')} alt="ropa" />
                        <div className="info">
                            <h3>Ganchos para Ropa</h3>
                            <Link to="/productos">Ver Productos</Link>
                        </div>
                    </div>
                    <div className="sect">
                        <img src={require('./img/otros.jpg')} alt="maquila" />
                        <div className="info">
                            <h3>Otros Productos</h3>
                            <Link to="/otros-productos">Ver Productos</Link>
                        </div>
                    </div>
                </div>
            </article>

            <Contacto />

            <footer></footer>
        </section >
    }
}