import React from 'react';
import Contacto from '../contacto';
import productos from './productos.js';
import { Link } from "react-router-dom";

let match = {}
export default class Productos  extends React.Component {


    constructor(props) {
        super(props);
        match = props.match
        this.state = {
            slide: 'one',
            count: 0
        }
    }

    changeSlide() {
        let numbers = ['cero', 'one', 'two', 'three', 'four'];
        this.setState({ count: this.state.count + 1 });
        this.state.count === 4 ? this.setState({ count: 0 }) : this.setState({ slide: numbers[this.state.count] })
    }

    componentDidMount() {
        document.getElementById('productos').scrollIntoView();
        let countSlide = setInterval(() => {
            this.changeSlide();
        }, 5000)
        this.setState(
            {countSlide: countSlide}
        )
        this.setState({ ganchos: productos.filter(gancho => gancho.categoria === 'ganchos')})
    }
    
    componentWillUnmount() {
        clearInterval(this.state.countSlide);
    }
    
    render(){
        return(
            <section className="productos-section">
                <article className="slider">
                    <div className={'slider-wrapper ' + this.state.slide} >
                        <div className="slide">
                            <img src={require('../img/slide.jpg')} alt="slide" />
                        </div>

                        <div className="slide">
                            <img src={require('../img/slide2.jpg')} alt="slide" />
                        </div>

                        <div className="slide">
                            <img src={require('../img/slide3.jpg')} alt="slide" />
                        </div>
                    </div>
                </article>

                <article className="productos-wrapper" id="productos">
                    { this.state.ganchos &&
                        this.state.ganchos.map(
                            (producto) => {
                                return <Link to={`${match.url}/${producto.id}`} className="producto" key={producto.id}>
                                    <div className="img">
                                        <img src={require("../img/productos/" + producto.images[0])} alt=""/>
                                    </div>
                                    <div className="info">
                                        <p>{producto.name}</p>
                                    </div>
                                </Link>
                            }
                        )
                    }
                </article>

                <article className="sections">
                    <h3>TAMBIÉN TE RECOMENDAMOS</h3>
                    <div className="three-wrap">
                        <div className="sect">
                            <img src={require('../img/otros.jpg')} alt="ropa" />
                            <div className="info">
                                <h3>Otros Productos</h3>
                                <Link to="/otros-productos">Ver Productos</Link>
                            </div>
                        </div>
                        <div className="sect">
                            <img src={require('../img/maquila.jpg')} alt="maquila" />
                            <div className="info">
                                <h3>Servicios de maquila</h3>
                                <Link to="/maquila">Ver Productos</Link>
                            </div>
                        </div>
                    </div>
                </article>

                <Contacto />

                <footer></footer>
            </section>
        )
    }

}

