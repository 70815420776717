import React from 'react';
import Contacto from './contacto';
import productos from './productos/productos';
import { Link } from "react-router-dom";

let match = {}

export default class Otros extends React.Component {
    
    constructor(props){
        super();
        match = props.match
        this.state = {
            gachos : []
        }
    }

    componentDidMount() {
        document.getElementById('otros').scrollIntoView();
        this.setState({ ganchos: productos.filter(gancho => gancho.categoria === 'otros') })
    }

    render() {
        return <section className="productos-section">
            <article className="slider">
                <div className="slider-wrapper">
                    <div className="slide">
                        <img src={require('./img/otros.jpg')} alt="slide" />
                        <h2>Otros <br/> Productos</h2>
                    </div>
                </div>
            </article>

            <article className="productos-wrapper" id="otros">
                {this.state.ganchos &&
                    this.state.ganchos.map(
                        (producto) => {
                            return <div className="producto" key={producto.id}>
                                <div className="img">
                                    <img src={require("./img/productos/" + producto.images[0])} alt="" />
                                </div>
                                <div className="info">
                                    <Link to={`${match.url}/${producto.id}`}>{producto.name}</Link>
                                </div>
                            </div>
                        }
                    )
                }
            </article>

            <article className="sections">
                <h3>TAMBIÉN TE RECOMENDAMOS</h3>
                <div className="three-wrap">
                    <div className="sect">
                        <img src={require('./img/ropa.jpg')} alt="ropa" />
                        <div className="info">
                            <h3>Ganchos para Ropa</h3>
                            <Link to="/productos">Ver Productos</Link>                            
                        </div>
                    </div>
                    <div className="sect">
                        <img src={require('./img/maquila.jpg')} alt="maquila" />
                        <div className="info">
                            <h3>Servicios de maquila</h3>
                            <Link to="/maquila">Ver Productos</Link>
                        </div>
                    </div>
                </div>
            </article>

            <Contacto />

            <footer></footer>
        </section>
    }
}