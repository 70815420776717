import React from 'react';
import './main.scss';
import Header from './header';
import Home from './home';
import Productos from './productos/productos.cmpt';
import Producto from './productos/producto-template.cmpt';
import Otros from './otros';
import Maquila from './maquila';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


function App() { 

  return (
    <Router basename="/">
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/productos" component={Productos} />
          <Route exact path="/otros-productos" component={Otros} />
          <Route exact path="/maquila" component={Maquila} />
          <Route path="/productos/:id" component={Producto} />
          <Route path="/otros-productos/:id" component={Producto} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
