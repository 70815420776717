import React from 'react';

export default class Contacto extends React.Component {

    render(){

        return (
            <article className="contacto" id="contacto">
                <div className="two-wrap">
                    <div className="info">
                        <h2>CONTACTO</h2>
                        <h3>En GRPlastic estamos a su servicio.</h3>
                        <p>Usted puede comunicarse con nosotros a través de los números telefónicos 5792-3425 o 2867-3685, enviarnos un correo electrónico a ventas@grplastic.com.mx o si lo desea llenar el siguiente formulario.</p>
                        <div className="form">
                            <h3>Envianos un mensaje</h3>
                            <form id="data">
                                <input name="nombre" type="text" placeholder="NOMBRE:" />
                                <input name="mail" type="text" placeholder="E-MAIL:" />
                                <textarea name="mensaje" cols="30" rows="10" placeholder="MENSAJE:"></textarea>
                                {/* <div id="g-cap"></div> */}
                                <button type="submit">ENVIAR</button>
                            </form>
                        </div>
                    </div>
                    <div className="map-sect">
                        <div className="map">
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.997796373564!2d-99.03142148465544!3d19.4125011868958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fcc72c6b197b%3A0x841e13664249b97!2sAv+Chimalhuac%C3%A1n+257%2C+Agua+Azul%2C+57500+Nezahualc%C3%B3yotl%2C+M%C3%A9x.!5e0!3m2!1ses!2smx!4v1564176432456!5m2!1ses!2smx" width="600" height="450" frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <h2>PUNTO DE VENTA</h2>
                        <p>Av. Chimalhuacan 257-A <br />Col. Agua Azul <br />Cd. Nezahualcoyotl CP 57500, <br />Edo. De México.</p>
                    </div>
                </div>
            </article>
        )
    }


}

